<template>
	<v-app>
		<loading
			:active.sync="isLoading"
			:can-cancel="false"
			:is-full-page="true"
			:width="80"
			:height="80"
			:opacity="0.7"
			color="cornflowerblue"
		></loading>

		<!--  左サイドバー -->
		<v-navigation-drawer class="fs_left_bar" app v-model="drawer" :width="330">
			<v-container>
				<!-- project name -->
				<v-list-item class="fs_project_title">
					<v-list-item-content>
						<v-list-item-title v-if="!this.$store.state.project.data.id"
							>プロジェクトなし</v-list-item-title
						>
						<v-list-item-title>{{
							this.$store.state.project.data.name
						}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<!-- project name END -->

				<v-btn class="close" @click="drawer = !drawer"
					><v-icon>{{ "mdi-close" }}</v-icon></v-btn
				>

				<div class="fs_main_buttons">
					<v-btn
						class="fs_button_top_map"
						@click="$router.push('/')"
						:class="pathIsHome()"
						>全体マップ</v-btn
					>
					<v-btn
						class="fs_button_top_photos"
						@click="$router.push('/camera')"
						:class="pathIsCamera()"
						>全体最新画像</v-btn
					>
				</div>
				<v-simple-table v-if="this.$store.state.project.data.id">
					<!-- <thead>
							<tr>
								<th class="" >ID</th>
								<th class="" >設置場所</th>
							</tr>
						</thead> -->
					<tbody>
						<tr
							v-for="item in places"
							:key="item.id"
							@click="clickRow(item)"
							v-bind:class="[
								item.id == $route.params.id ? 'row-selected' : 'none',
							]"
						>
							<td>{{ ("000" + item.id).slice(-3) }}</td>
							<td>
								<div>
									<span> {{ item.name }} </span>
								</div>
							</td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-container>
		</v-navigation-drawer>
		<!--  左サイドバーEND -->

		<!--  トップバー -->
		<v-app-bar app dense flat>
			<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
			<v-row justify="center" align="center">
				<v-toolbar-title
					class="logo"
					@click="$router.push('/')"
				></v-toolbar-title>
			</v-row>
			<!-- <v-spacer></v-spacer> -->

			<v-toolbar-items style="cursor: pointer">
				<v-menu offset-y>
					<template v-slot:activator="{ on }">
						<v-avatar v-on="on" size="36">
							<img :src="login.image" :alt="login.name" />
						</v-avatar>
					</template>
					<v-list>
						<v-subheader>マイアカウント</v-subheader>
						<v-list-item link @click.stop="$router.push('/mypage')">
							<v-list-item-icon>
								<v-icon>{{ "mdi-account" }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>マイページ</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item
							v-show="login.role == 'admin'"
							link
							@click.stop="$router.push('/admin/project')"
						>
							<v-list-item-icon style="margin-right: 10px">
								<v-icon>{{ "mdi-account-supervisor" }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content style="margin-right: 10px">
								<v-list-item-title>管理画面</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item link @click.stop="logoutDialog = true">
							<v-list-item-icon>
								<v-icon>{{ "mdi-logout" }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>ログアウト</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-toolbar-items>
		</v-app-bar>
		<!--  トップバー END-->

		<v-main style="padding-top: 0">
			<router-view :key="$route.fullPath" @loadShowHide="triggerLoading" />
		</v-main>

		<!-- modal -->
		<div class="text-center">
			<v-dialog v-model="logoutDialog" width="500">
				<v-card>
					<v-card-title class="headline"> マイアカウント </v-card-title>
					<v-card-text>ログアウトしますか？</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="green darken-1" text @click="logoutDialog = false">
							いいえ
						</v-btn>
						<v-btn color="green darken-1" text @click="logout"> はい </v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
		<!-- modal END-->
	</v-app>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
	components: {
		loading: Loading,
	},
	data() {
		return {
			drawer: null,
			logoutDialog: false,
			isLoading: this.$store.state.isLoading,
			places: this.$store.state.places.data,
			login: this.$store.state.user.data,
		};
	},
	mounted() {
		this.scrollTop();
	},
	methods: {
		logout: function () {
			this.logoutDialog = false;
			this.isLoading = true;
			if (this.$store.state.auth.data.tokenType == "azure") {
				this.$store.dispatch("auth/unsetData");
				this.$store.dispatch("user/unsetData");
				this.$store.dispatch("project/unsetData");
				this.$store.dispatch("places/unsetData");
				location.href =
					"https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=" +
					this.config.encodedLoginUrl;
			} else if (this.$store.state.auth.data.tokenType == "line") {
				this.$store.dispatch("auth/unsetData");
				this.$store.dispatch("user/unsetData");
				this.$store.dispatch("project/unsetData");
				this.$store.dispatch("places/unsetData");
				axios.defaults.headers.common["Authorization"] =
					"Bearer " + this.$store.state.auth.data.token;
				axios
					.get(
						"https://" +
							this.config.defaultOriginString +
							"/api/logout?tokenType=" +
							this.$store.state.auth.data.tokenType
					)
					.then((res) => {
						this.isLoading = false;
						// 成功した場合
						if (res && res.data && res.data.status == "success") {
							console.log("Success: " + JSON.stringify(res.data));
						} else {
							console.log("Fail: " + JSON.stringify(res));
						}
						this.$router.push({ name: "Login" }).catch(() => {});
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				this.$store.dispatch("auth/unsetData");
				this.$store.dispatch("user/unsetData");
				this.$store.dispatch("project/unsetData");
				this.$store.dispatch("places/unsetData");
				location.href =
					"https://" + this.config.defaultOriginString + "/login";
			}
		},
		scrollTop: function () {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		clickRow(it) {
			this.$router
				.push({ name: "Place", params: { id: it.id, item: it } })
				.catch(() => {});
		},
		pathIsHome() {
			return this.$route.name == "Home" ? "selected" : "";
		},
		pathIsCamera() {
			return this.$route.name == "Camera" ? "selected" : "";
		},
		triggerLoading(b) {
			if (b == "true" || b == true) {
				this.isLoading = true;
			} else {
				this.isLoading = false;
			}
		},
	},
};
</script>
